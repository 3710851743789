.contact-us-form {
  >form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1vw;
    width: 100%;
    height: auto;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    background-color: rgba(87, 87, 87, 0.1);
    border-radius: 0.5vw;
    padding: 0.5vw;

    label {
      font-size: 0.8vw;
      font-weight: 300;
    }

    input,
    textarea {
      width: 100%;
      border-radius: 0.5vw;
      font-size: 0.9vw;
      padding: 0.5vw;
      outline: none;
      border: none;
      color: white !important;
    }

    textarea {
      height: 5vw;
    }

    input[type="number"] {
      height: 2.5vw;
    }

    &.area {
      padding-bottom: 1vw;
      grid-column: span 2 / span 2;
    }
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    grid-column: span 2 / span 2;

    p {
      display: flex;
      gap: 0.5vw;
    }

    .error-message {
      color: #e53e3e;
      /* red-600 */
      font-weight: 600;
      font-size: 0.9vw;
    }

    .success-message {
      color: #38a169;
      /* green-600 */
      font-weight: 600;
      font-size: 0.9vw;
    }

    .submit-button {
      background-color: rgba(87, 87, 87, 0.1);
      border-radius: 0.5vw;
      font-size: 0.9vw;
      padding: 0.5vw 2vw;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .contact-us-form {
    >form {
      display: flex;
      flex-direction: column;
      gap: 2.5vh;
    }

    .input-container {
      border-radius: 1vh;
      padding: 1vh;
      gap: 1vh;

      label {
        font-size: 1.5vh;
        padding: 1vh;
      }

      input,
      textarea {
        width: 100%;
        border-radius: 1vh;
        font-size: 1.8vh;
        padding: 1vh;
        outline: none;
        border: none;
        color: white !important;
      }

      textarea {
        height: 10vh;
      }

      input[type="number"] {
        height: 4.3vh;
      }

      &.area {
        padding-bottom: 2vh;
      }
    }

    .form-footer {
      p {
        display: flex;
        gap: 1vh;
      }

      .error-message {
        font-size: 1.8vh;
      }

      .success-message {
        font-size: 1.8vh;
      }

      .submit-button {
        border-radius: 0.8vh;
        font-size: 1.8vh;
        padding: 1vh 4vh;
      }
    }
  }

}