@use './../../../../../global/styles/variables.scss' as *;

#about-us {
  position: relative;
  width: 100vw;
  min-height: 50vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vw 0;

  .page-decoration-component {
    position: absolute;
    top: 0;
    z-index: 0;

    &.left {
      left: 0;
      width: 10vw;
    }

    &.right {
      right: 0;
      width: 10vw;
    }
  }

  >article {
    width: 75vw;
    height: auto;

    section {
      width: 100%;

      &.header-section {
        position: relative;
        height: 9vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top-left-radius: 1vw;
        border-top-right-radius: 1vw;
        text-align: center;
        background-color: rgba(87, 87, 87, 0.1);

        h2 {
          font-size: 3vw;
          font-weight: 500;
          letter-spacing: 0.1em;
          padding-bottom: 1.5vw;
        }

        .gradient-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -10;
          width: 100%;
          height: 1.2vw;
          background: linear-gradient(to right, $yellow-dark, $purple-dark, $blue-dark);
        }
      }

      &.content-section {
        position: relative;
        height: auto;

        about-us-grid-component {
          position: relative;
          z-index: 10;
        }

        .content-box {
          position: relative;
          width: 100%;
          height: 6vw;
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 1vw;
          padding: 1.5vw 5vw 0.5vw;
          border-bottom-left-radius: 1vw;
          border-bottom-right-radius: 1vw;
          background-color: rgba(87, 87, 87, 0.1);

          .gradient-bar {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -10;
            width: 100%;
            height: 1.2vw;
            background: linear-gradient(to right, $yellow-dark, $purple-dark, $blue-dark);
          }

          h2 {
            font-size: 2vw;
            font-weight: bold;
            letter-spacing: 0.1em;
          }

          p {
            z-index: -10;
            font-size: 1.1vw;
            font-weight: 300;
          }
        }
      }
    }

    about-us-carousel-component {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #about-us {
    padding: 8vh 0;
    min-height: fit-content;
    height: auto;

    .page-decoration-component {
      display: none;
    }

    >article {
      width: 90vw;

      section {
        &.header-section {
          height: 15vh;
          background-color: transparent;

          h2 {
            font-size: 4vh;
            padding-bottom: 2vh;
          }

          .gradient-bar {
            height: 2.5vh;
          }
        }

        &.content-section {
          display: none;
        }
      }

      about-us-carousel-component {
        display: block;
      }
    }
  }
}