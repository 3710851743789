#enterLanding {
  width: 100vw;
  height: 100dvh;
  display: grid;
  place-items: center;
  background-color: #1A1A1A;
  animation: scaleText 1.2s 2.5s ease forwards;

  p {
    font-size: 10vh;
    font-family: 'Bicubik', serif;

    &.mock {
      position: absolute;
      color: white;
      opacity: 0;
    }
  }

  #paragraph {
    font-size: 5vw;

    @media (min-width: 768px) {
      font-size: 5vw;
    }

    text-transform: uppercase;
    font-family: 'Bicubik',
    sans-serif;
  }
}

@media (max-width: 768px) {
  #enterLanding {
    p {
      font-size: 6vh;
    }
  }
}

@keyframes scaleText {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    transform: scale(180);
    opacity: 0;
  }
}

@keyframes hiddenTo {
  to {
    z-index: 1;
  }
}
