@use "./../../../../../global/styles/variables.scss" as *;

.about-us-carousel-bottom-bar {
  width: 100%;
  height: 1.5vh;
  display: flex;
  justify-content: center;
  gap: 1vh;

  >div {
    width: 1.5vh;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background: white;
  }

  >div:nth-child(1).active {
    background: $blue;
  }

  >div:nth-child(2).active {
    background: $yellow;
  }

  >div:nth-child(3).active {
    background: $purple;
  }
}