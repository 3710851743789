@use 'enter-landing.component.scss';
@use 'page-decoration.component.scss';
@use 'silver-logo.component.scss';
@use 'space.component.scss';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  text-decoration: none;
  list-style: none;
  outline: none;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow-y: auto;
  background: #1A1A1A;
  color: white;

  a {
    color: white;
  }

  button {
    cursor: pointer;
    border: none;
    color: white;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
