.footer {
  width: 100vw;
  height: 20vw;
  display: grid;
  place-items: center;
  padding: 2.5vw 10vw;
  background-color: #191919;

  >section:nth-child(1) {
    width: 100%;

    >silver-logo-component {
      width: 15vw;
    }
  }

  >section:nth-child(2) {
    width: 100%;
    display: flex;
    align-items: center;

    >article:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 0.5vw;


      >div,
      a {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 0.5vw;

        >div {
          width: 2.5vw;
          display: grid;
          place-items: center;

          >img {
            width: auto;
            height: 1vw;
          }
        }

        >p {
          font-size: 0.8vw;
          font-weight: 300;
        }
      }
    }

    >article:nth-child(2) {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      >ul {
        display: flex;
        gap: 2.5vw;

        >li {
          font-size: 0.8vw;
          font-weight: 300;
          text-transform: uppercase;
          cursor: pointer;
          transition: font-weight 0.3s;
        }

        >li:hover {
          font-weight: 600;
        }
      }
    }

    >article:nth-child(3) {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1vw;

      >p {
        font-size: 0.9vw;
        padding-left: 0.5vw;
      }

      >section {
        display: flex;
        flex-direction: column;
        gap: 1vw;

        >a {
          display: flex;
          align-items: center;
          justify-content: start;
          gap: 0.5vw;

          >div {
            width: 2.5vw;
            display: grid;
            place-items: center;

            >img {
              width: auto;
              height: 1vw;
            }
          }

          >p {
            font-size: 0.8vw;
            font-weight: 300;
          }
        }
      }

      >div {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 0.5vw;

        >div {
          width: 2.5vw;
          display: grid;
          place-items: center;

          >img {
            width: auto;
            height: 1vw;
          }
        }

        >p {
          font-size: 0.8vw;
          font-weight: 300;
        }
      }
    }
  }

  >section:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    >p {
      font-size: 0.7vw;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    height: 35vh;
    padding: 2.5vh;
    gap: 0;

    >section:nth-child(1) {
      height: auto;

      >silver-logo-component {
        width: 10vh;
      }
    }

    >section:nth-child(2) {
      display: flex;
      justify-content: space-between;
      align-items: start;

      >article:nth-child(1) {
        width: 60%;
        gap: 1.5vh;

        >div,
        a {
          >div {
            min-width: 3vh;
            max-width: 3vh;

            >img {
              height: 2vh;
            }
          }

          >p {
            font-size: 1.2vh;
          }
        }
      }

      >article:nth-child(2) {
        display: none;
      }

      >article:nth-child(3) {
        gap: 1.5vh;

        >p {
          font-size: 1.3vh;
        }

        >section {
          flex: 1;
          gap: 1.5vh;

          >a {
            >div {
              min-width: 3vh;
              max-width: 3vh;

              >img {
                height: 2vh;
              }
            }

            >p {
              font-size: 1.2vh;
            }
          }
        }

        >div {
          >div {
            width: 3vw;

            >img {
              height: 1.5vw;
            }
          }

          >p {
            font-size: 1vw;
          }
        }
      }
    }

    >section:nth-child(3) {
      >p {
        font-size: 1.2vh;
      }
    }
  }
}