#our-projects {
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vw 0;

  >article {
    width: 75vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding: 0 0 3vw 0;
    border-radius: 1vw;
    background-color: rgb(87 87 87 / 0.1);

    >section:nth-child(1) {
      position: relative;
      width: 100%;
      height: 7.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2 {
        font-size: 3vw;
        font-weight: 500;
        letter-spacing: 0.1575vw;
      }
    }

    >section:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1vw;
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }
}

@media (max-width: 768px) {
  #our-projects {
    padding: 8vh 0;

    >article {
      width: 90vw;
      gap: 2.5vh;
      padding: 2.5vh 0;
      background-color: transparent;
      overflow: hidden;

      >section:nth-child(1) {
        height: auto;

        >h2 {
          font-size: 4vh;
          padding-bottom: 2vh;
        }

        >p {
          display: none;
        }

      }

      >section:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2.5vh;
        padding-left: 0;
        padding-right: 0;

        our-project-card-component {
          width: 91%;
        }
      }
    }

  }
}