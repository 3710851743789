nav {
  position: relative;
  width: 100vw;
  height: 5vw;
  backdrop-filter: blur(0.25vw);
  background-color: rgba(0, 0, 0, 0.25);
  transition: height 0.5s;
  overflow: hidden;

  >section:nth-child(2) {
    position: absolute;
    top: 1.2vw;
    left: 50%;
    transform: translateX(-50%);
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >silver-logo-component {
      width: 7.5vw;
    }

    >ul {
      display: flex;
      gap: 2.5vw;

      >li {
        font-size: 0.8vw;
        font-weight: 300;
        cursor: pointer;
        text-transform: uppercase;
        transition: font-weight 0.2s;
      }

      >li:hover {
        font-weight: 500;
      }
    }

    >div {
      display: none;
    }
  }

  >section:nth-child(1) {
    display: none;
  }
}

@media (max-width: 768px) {
  nav {
    height: 8vh;
    background-color: rgba(0, 0, 0, 0.40);

    >section:nth-child(2) {
      top: 1vh;
      width: 94vw;

      >silver-logo-component {
        width: 15vh;
        padding-top: 1vh;

        >img {
          width: 20vh;
        }
      }

      >ul {
        display: none;

        >li {
          font-size: 1.5vh;
        }
      }

      >div {
        display: block;
      }
    }

    >section:nth-child(1) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: all 0.5s 0.5s;
      opacity: 0;

      >ul {
        display: flex;
        flex-direction: column;
        gap: 5vh;

        >li {
          font-size: 2.5vh;
          font-weight: 600;
          cursor: pointer;
          text-transform: uppercase;
          transition: font-weight 0.2s;
        }
      }
    }
  }
}

nav.open {
  >section:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.50);
    animation: fadeIn 0.5s 0.3s forwards;
  }
}

nav.close {
  >section:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.25);
    animation: fadeOut 0.5s forwards;
  }
}

.open {
  height: 100vh;
  align-items: flex-start;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.hamburger {
  width: 30px;
  height: 30px;
  cursor: pointer;

  span {
    display: block;
    margin: 5px auto;
    background-color: white;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    transition: all 0.3s ease;
  }

  &:hover {
    span:first-child {
      transform: translateY(-2px);
    }

    span:last-child {
      transform: translateY(2px);
    }
  }
}

.hamburger.is-opened {
  span:first-child {
    transform: translateY(10px) rotate(45deg);
  }

  span:nth-child(2) {
    transform: scaleX(0);
  }

  span:last-child {
    transform: translateY(-6px) rotate(-45deg);
  }
}
