@use './../../../../../global/styles/variables.scss' as *;

.home-service-bar-component {
  height: 3vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-content: center;
  gap: 2.5vw;
  padding: 0.125vw 2.5vw 0 2.5vw;
  border-radius: 20vw;
  background-color: rgba(87, 87, 87, 0.2);
  font-weight: 600;
  font-size: 0.85vw;

  a:nth-child(1) {
    color: $yellow;
  }

  a:nth-child(2) {
    color: $purple;
  }

  a:nth-child(3) {
    color: $blue;
  }
}

@media (max-width: 768px) {
  .home-service-bar-component {
    height: 4vh;
    padding: 0.25vh 5vh 0 5vh;
    font-size: 1.2vh;
    gap: 3vh;
    margin-top: 2vh;
  }
}