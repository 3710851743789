.about-us-grid-section {
  height: 56vw;
  overflow: hidden;

  .about-us-order {
    &.step-1 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2vw;
      width: 100%;
      height: 100%;
    }

    .grid-element-1,
    .grid-element-2,
    .grid-element-3 {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 1vw;
    }
  }
}


//Grid Animation
.grid-element-1,
.grid-element-2,
.grid-element-3 {
  position: absolute;
}


.about-us-order.step-1 {
  display: flex;
  flex-wrap: wrap;
  gap: 2vh;
  transition: all 0.5s;

  .grid-element-1 {
    top: 0;
    left: 0;
    width: 75vw;
    height: 35vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: row;
      align-items: center;
    }

    .about-image-text-container {
      padding-left: 1.25vw;

      div:first-child {
        left: 1.25vw;
      }
    }
  }

  .grid-element-2 {
    top: 36vw;
    left: 0;
    width: 25vw;
    height: 20vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: column;
      align-items: start;
    }

    .about-image-text-container {
      padding-left: 0;

      div:first-child {
        left: 0;
      }
    }
  }

  .grid-element-3 {
    top: 36vw;
    left: 26vw;
    width: 49vw;
    height: 20vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: row;
      align-items: center;
    }

    .about-image-text-container {
      padding-left: 1.25vh;

      div:first-child {
        left: 1.25vh;
      }
    }
  }
}

.about-us-order.step-1.step-2 {
  .grid-element-2 {
    top: 0;
    left: 0;
    width: 75vw;
    height: 35vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: row;
      align-items: center;
    }

    .about-image-text-container {
      padding-left: 1.25vh;

      div:first-child {
        left: 1.25vh;
      }
    }
  }

  .grid-element-3 {
    top: 36vw;
    left: 0;
    width: 25vw;
    height: 20vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: column;
      align-items: start;
    }

    .about-image-text-container {
      padding-left: 0;

      div:first-child {
        left: 0;
      }
    }
  }

  .grid-element-1 {
    top: 36vw;
    left: 26vw;
    width: 49vw;
    height: 20vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: row;
      align-items: center;
    }

    .about-image-text-container {
      padding-left: 1.25vw;

      div:first-child {
        left: 1.25vw;
      }
    }
  }
}

.about-us-order.step-1.step-2.step-3 {
  .grid-element-3 {
    top: 0;
    left: 0;
    width: 75vw;
    height: 35vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: row;
      align-items: center;
    }

    .about-image-text-container {
      padding-left: 1.25vw;

      div:first-child {
        left: 1.25vw;
      }
    }
  }

  .grid-element-1 {
    top: 36vw;
    left: 0;
    width: 25vw;
    height: 20vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: column;
      align-items: start;
    }

    .about-image-text-container {
      padding-left: 0;

      div:first-child {
        left: 0;
      }
    }
  }

  .grid-element-2 {
    top: 36vw;
    left: 26vw;
    width: 49vw;
    height: 20vw;
    transition: width 0.5s, height 0.5s;

    section {
      flex-direction: row;
      align-items: center;
    }

    .about-image-text-container {
      padding-left: 1.25vw;

      div:first-child {
        left: 1.25vw;
      }
    }
  }
}