#main {
  position: relative;
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5vw;
  overflow: hidden;

  .space-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  article {
    position: relative;
    text-align: center;

    h1 {
      font-size: 3vw;
      font-weight: 600;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    h2 {
      font-size: 1.8vw;
      font-weight: 300;
      letter-spacing: 0.02em;

      &.text-light {
        font-size: 1.5vw;
        font-weight: 200;
        padding: 0 15vw;
      }
    }
  }

  .link {
    position: absolute;
    bottom: 2.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5vw;
    transform: scale(0);
    animation: scaleIn 0.5s forwards;
    cursor: pointer;

    p {
      font-size: 0.9vw;
      font-weight: 300;
      letter-spacing: 0.1em;
    }

    .lucide-icon {
      width: 2vw;
      height: 2vw;
      animation: bounce 1s infinite;
      stroke-width: 1;
    }
  }
}

@media (max-width: 768px) {
  #main {
    padding-inline: 5vh;

    article {
      h1 {
        font-size: 4vh;
      }

      h2 {
        font-size: 2.5vh;
        font-weight: 100;

        &.text-light {
          font-size: 1.5vh;
          margin-top: 2vh;
          padding: 0
        }
      }
    }

    .link {
      p {
        font-size: 1.8vh;
      }

      .lucide-icon {
        width: 3.5vh;
        height: 3.5vh;
      }
    }
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10%);
  }
}
