@use "./../../../../../global/styles/variables.scss" as *;

.about-us-images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  >section {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: start;
    gap: 1vw;
    padding: 1.5vw;

    >img {
      width: 10vw;
      height: auto;
    }

    >article {
      position: relative;
      height: fit-content;
      display: flex;
      align-items: start;

      >div:nth-child(1) {
        position: absolute;
        width: 0.3vw;
        height: 100%;
        padding-top: 0.4vw;

        >div {
          width: 100%;
          height: 100%;
        }
      }

      >div:nth-child(2) {
        width: 20vw;
        margin-left: 1vw;

        >p:nth-child(1) {
          font-size: 1.2vw;
          font-weight: 600;
          text-transform: uppercase;
        }

        >p:nth-child(2) {
          font-size: 0.8vw;
          font-weight: 300;
        }
      }
    }
  }
}

.bg-blue {
  background-color: $blue;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-purple {
  background-color: $purple;
}