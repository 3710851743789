.silver-logo {
  width: 100%;
  max-width: 10vw;
  height: auto;

  img {
    width: 100%;
    max-width: 10vw;
    height: auto;
  }
}

@media (max-width: 768px) {
  .silver-logo {
    max-width: 30vh;

    img {
      max-width: 18vh;
    }
  }
}