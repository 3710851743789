@use "./../../../../../global/styles/variables.scss" as *;

.about-us-slider-images {
  position: relative;
  width: 100%;
  height: 100%;

  >img {
    height: 100%;
    object-fit: cover;
  }

  >section {
    position: absolute;
    bottom: 0;
    left: 0;

    >img {
      width: 20vh;
      height: auto;
      margin-left: 2.5vh;
    }

    >article {
      margin: 2.5vh;
      display: flex;

      div:nth-child(1) {
        width: 0.8vh;
        min-height: 100%;
        padding-top: 0.3vh;
      }

      div:nth-child(2) {
        margin-left: 1vh;

        >p:nth-child(1) {
          font-size: 2.5vh;
          font-weight: 700;
          text-transform: uppercase;
        }

        >p:nth-child(2) {
          margin-top: 0.5vh;
          font-size: 1.8vh;
          font-weight: 300;
        }
      }
    }
  }
}

.bg-yellow {
  background-color: $yellow;
}

.bg-blue {
  background-color: $blue;
}

.bg-purple {
  background-color: $purple;
}