.about-us-carousel {
  position: relative;
  width: fit-content;
  border-bottom-left-radius: 2.5vh;
  border-bottom-right-radius: 2.5vh;

  div {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .swiper-slide {
    width: 100%;
    height: 50vh;
    display: flex;
    overflow: hidden;
    border-bottom-left-radius: 3vh;
    border-bottom-right-radius: 3vh;
  }

  about-us-slider-image-component {
    position: relative;
    width: 100%;
    height: 100%;
  }

  about-us-carousel-bottom-bar-component {
    position: absolute;
    bottom: -8vh;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 5vh;
  }
}