#our-customers {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vw 0;

  >article {
    width: 75vw;
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    padding: 2.5vw 0 3vw 0;
    border-radius: 1vw;
    background-color: rgba(87, 87, 87, 0.1);

    >section:nth-child(1) {
      position: relative;
      width: 100%;
      height: 7.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      >h2 {
        font-size: 3vw;
        font-weight: 500;
        letter-spacing: 0.1575vw;
      }

      >p {
        margin-top: 0.25vw;
        width: 70%;
        font-size: 1vw;
        font-weight: 300;
      }
    }

    >section:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1vw;
      padding-inline: 5vw;

      >img {
        width: auto;
        height: 2.5vw;
        object-fit: contain;
        filter: grayscale(100%);
      }
    }
  }
}

@media (max-width: 768px) {
  #our-customers {
    padding: 8vh 0;

    >article {
      width: 90vw;
      gap: 4vh;
      background-color: transparent;

      >section:nth-child(1) {
        height: 15vh;

        >h2 {
          font-size: 4vh;
        }

        >p {
          margin-top: 1vh;
          width: 100%;
          font-size: 1.5vh;
        }
      }

      >section:nth-child(2) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        gap: 2.5vh;

        >img {
          height: 3vh;
        }
      }
    }
  }
}