.space-canvas {
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
}

.space-nebulous {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  opacity: 0.15;

  &.md-hidden {
    @media (max-width: 767px) {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  &.md-block {
    @media (min-width: 768px) {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 767px) {
  .space-nebulous {
    display: block;
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}