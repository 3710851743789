#our-tools {
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vw 0;

  >article {
    width: 75vw;
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    padding: 2.5vw 0 3vw 0;
    border-radius: 1vw;
    background-color: rgb(87 87 87 / 0.1);


    >section:nth-child(1) {
      position: relative;
      align-self: center;
      width: 90%;
      height: 7.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2 {
        font-size: 3vw;
        font-weight: 500;
        letter-spacing: 0.1575vw;
      }

      p {
        margin-top: 0.25vw;
        width: 70%;
        font-size: 1vw;
        font-weight: 300;
      }
    }

    >section:nth-child(2) {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1vw;
      padding: 0 5vw;
    }

    >section:nth-child(3) {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #our-tools {
    padding: 8vh 0;

    >article {
      width: 90vw;
      gap: 4vh;
      padding: 2.5vh 0;
      background-color: transparent;
      overflow: hidden;

      >section:nth-child(1) {
        height: 15vh;

        >h2 {
          font-size: 4vh;
        }

        >p {
          margin-top: 1vh;
          width: 100%;
          font-size: 1.5vh;
        }
      }

      >section:nth-child(2) {
        display: none;
      }

      >section:nth-child(3) {
        display: flex;
        flex-direction: column;
        gap: 2.5vh;

        >div:nth-child(1) {
          position: relative;
          display: flex;
          gap: 2.5vh;
          animation: slide 16s 2s linear infinite;
        }

        >div:nth-child(2) {
          display: flex;
          gap: 2.5vh;
          animation: slide-inverse 16s 2s linear infinite;
        }
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(1vh);
  }

  50% {
    transform: translateX(-107vw);
  }

  100% {
    transform: translateX(1vh);
  }
}

@keyframes slide-inverse {
  0% {
    transform: translateX(-107vw);
  }

  50% {
    transform: translateX(1vh);
  }

  100% {
    transform: translateX(-107vw);
  }
}