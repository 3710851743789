@use './../../../../../global/styles/variables.scss' as *;

.project-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  padding: 2.5vw;
  border-radius: 0.5vw;
  background-color: rgba(87, 87, 87, 0.1);

  >video {
    width: 100%;
    height: 15vw;
    object-fit: cover;
  }

  >section {
    display: flex;
    flex-direction: column;
    gap: 1vw;

    div:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 0.5vw;

      h3 {
        font-size: 1.3vw;
        font-weight: bold;
      }

      p {
        font-size: 0.8vw;
        font-weight: 300;
      }
    }


    div:nth-child(2) {
      display: flex;
      gap: 1vw;

      .service {
        width: 7vw;
        height: 2vw;
        display: grid;
        place-content: center;
        border-radius: 0.25vw;
        font-size: 0.75vw;
        font-weight: bold;
        background-color: rgba(87, 87, 87, 0.2);

        &.service-yellow {
          color: $yellow;
        }

        &.service-purple {
          color: $purple;
        }

        &.service-blue {
          color: $blue;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .project-card {
    width: 90vw;
    padding: 2.5vh;
    gap: 2.5vh;
    border-radius: 2vh;

    >video {
      border-radius: 0.8vh;
      width: 100%;
      height: auto;
    }

    >section {
      gap: 2.5vh;

      div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 1vh;

        h3 {
          font-size: 2vh;
        }

        p {
          font-size: 1.5vh;
          font-weight: 300;
        }
      }

      div:nth-child(2) {
        gap: 2vh;

        .service {
          width: 12vh;
          height: 3vh;
          font-size: 1.2vh;
          border-radius: 0.5vh;
        }
      }
    }
  }
}