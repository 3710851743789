#landing-layout {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-image: url('./../../../assets/images/global-background.webp');
  background-size: contain;
  background-repeat: repeat-y;
  overflow: auto;

  navbar-component {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 8vh;
  }

  main {
    width: 100vw;
  }

  enter-landing-component {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    animation: outRange 0s 2.8s forwards;
  }
}

@keyframes outRange {
  100% {
    display: none;
  }
}
