@use './global/styles/index.scss' as *;
@use './silver-landing/presentation/styles/index' as *;
@use './silver-landing/presentation/views/home/styles/index.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/effect-coverflow";

@font-face {
  font-family: 'Bicubik';
  src: url('assets/fonts/bicubik.otf') format('woff2');
}

$gray-transparent: #57575750;
$yellow: #FFB86B;
$yellow-dark: #ffAA4D;
$blue: #487bA4;
$blue-dark: #1D517A;
$purple: #9264A7;
$purple-dark: #4D1267;