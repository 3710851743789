#contact-us {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6vw 0;

  >article {
    width: 75vw;
    display: flex;
    flex-direction: column;
    gap: 2.5vw;
    padding: 2.5vw 0 3vw 0;
    border-radius: 1vw;
    background-color: rgb(87 87 87 / 0.1);

    >section:nth-child(1) {
      position: relative;
      width: 100%;
      height: 7.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2 {
        font-size: 3vw;
        font-weight: 500;
        letter-spacing: 0.1575vw;
      }

      p {
        margin-top: 0.25vw;
        width: 85%;
        font-size: 1vw;
        font-weight: 300;
      }
    }

    >section:nth-child(2) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1vw;
      padding-inline: 5vw;

      >contact-us-form-component {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  #contact-us {
    padding: 8vh 0;

    >article {
      width: 90vw;
      gap: 4vh;
      padding: 2.5vh 0;
      background-color: transparent;
      overflow: hidden;

      >section:nth-child(1) {
        height: 15vh;

        >h2 {
          font-size: 4vh;
        }

        >p {
          margin-top: 1vh;
          width: 100%;
          font-size: 1.5vh;
        }
      }

      >section:nth-child(2) {
        flex-direction: column;
        gap: 4vh;
        padding-inline: 0;

        >contact-us-form-component {
          width: 100%;
        }
      }
    }
  }
}