@use './../../../../../global/styles/variables.scss' as *;

.our-tools-card-component {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  padding: 1vw;
  border-radius: 0.5vw;
  background-color: rgba(87, 87, 87, 0.1);

  >div:nth-child(1) {
    h2 {
      width: 10vw;
      color: $blue;
      font-weight: bold;
      font-size: 1vw;
    }

    p {
      width: 10vw;
      font-size: 0.8vw;
      font-weight: 300;
    }
  }

  >div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5vw;

    img {
      width: auto;
      height: 2vw;
      object-fit: contain;
      filter: grayscale(100%);
    }
  }
}

@media (max-width: 768px) {
  .our-tools-card-component {
    width: 60vw;
    height: 40vw;
    display: flex;
    flex-direction: column;
    padding: 8vw;
    gap: 4vw;
    border-radius: 4vw;
    overflow: hidden;

    >div:nth-child(1) {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        width: 100%;
        font-size: 4vw;
        text-align: center;
      }

      p {
        display: none;
      }
    }

    >div:nth-child(2) {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 4vw !important;

      img {
        height: 4vw;
      }
    }
  }
}